.social-instagram a {
    background: #da004e;
}

.social-instagram a:hover {
    background: #f23679;
}

.social-spotify a {
    background: #00d856;
}

.social-spotify a:hover {
    background: #08fe6a;
}

.social-linkedin a {
    background: #0275b4;
}

.social-linkedin a:hover {
    background: #2297d7;
}

.social-youtube a {
    background: #fb000f;
}

.social-youtube a:hover {
    background: #f85b65;
}